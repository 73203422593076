export default `
   .     .                     *                                   
      *     *  .                                                   
  .     . * .   *     *                   .                        
  .     .   .                                                      
                                  *                                
  *         .               . 
                                
                               
        *

,--.  ,--.       ,--.,--.
|  '--'  | ,---. |  ||  | ,---.
|  .--.  || .-. :|  ||  || .-. |                 :              
|  |  |  |\\   --.|  ||  |' '-' '                 !           .  
\`--'  \`--' \`----'\`--'\`--' \`---'               *  |        .             
                                                 |_         +      
    .                *       .                ,  | '.                   
          .                           -- - --+ -<#> -+- --- --  -         
            ,                                 '._|_,'               *
    *     .         .           .      *         T
                                                 |                   
                          *                      !                  
    .         .                                  :         . :      
                                                 .       *          
                                                                    
.                      .            .                               
*             *       *                   .               *         
                 .                    .                             
*                    .         . *         .                        
.                                                     *
                     .        .          .             
.                                                        .
                .               .                      \\ | /       
  *                                                    --+--       
.                                       *              / ! \\       


This site was built by Mark Sauer-Utley in Brooklyn, NY.
See marksauerutley.com for more stuff.
`
